import * as Cookie from '@integrabeauty/cookie';
import * as SentryBrowser from '@sentry/browser';
import type * as SentryTypes from '@sentry/core';

declare global {
  /**
   * Undocumented global declared as a side effect of initializing Sentry
   */
  // eslint-disable-next-line no-var
  var SENTRY_RELEASE: {
    id: string;
  };

  /**
   * Undocumented global declared as a side effect of initializing Sentry
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var __SENTRY__: Record<string, any>;

  /**
   * Undocumented global declared as a side effect of initializing Sentry
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var __sentry_instrumentation_handlers__: unknown;
}

/**
 * Declare the types for the globals setup by the loader snippet.
 */
declare global {
  /**
   * Sentry data source name. Initialized in liquid. Looks like a url.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var sentry_dsn: string;

  /**
   * Internal event queue that captures events prior to this pixel code loading.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var sentry_event_queue: Event[];

  /**
   * Listens for events prior to this code loading.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var sentry_event_listener: (event: Event)=> void;

  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var sentry_market_handle: string;

  /**
   * This global is defined when this module loads. It exposes a simple global function so that
   * sentry errors can be easily triggered via the console to test whether diagnostics are working
   *
   * This is not typed specifically because this is never called by code. The type is irrelevant.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var sentry_test: unknown;
}

/**
 * We prefer to filter errors server side so that we can revise filtering configuration without a
 * deployment. However, there are some errors we will never care about, and there are some errors
 * that we know about that happen on almost every page load, leading to millions of error events, to
 * the point where we exceed error tracking quotas very quickly.
 */
const denyUrls: SentryBrowser.BrowserOptions['denyUrls'] = [
  /^chrome:/i,
  /^chrome-extension:/i,
  /extension:/i,
  /^moz-extension:/i,
  /^pptr:/i,
  /^safari-extension:/i,
  /^safari-web-extension:/i,
  /^webkit-masked-url:/i,
  /^file:/i,
  /cdn\/wpm/i,
  /paypalobjects/i,
  /shopifycloud\/shopify/i,
  /shopifycloud\/payment-sheet/i,
  /shopifycloud\/perf-kit/i,
  /cdn\/s\/trekkie/i,
  /cdn\/shopifycloud\/shopify\/assets\/shop_events_listener/i,
  /doubleclick\.net\/pagead/i,
  /cdn\.gladly\.com/i,
  /bat\.bing\.com/i,
  /analytics\.tiktok\.com/i,
  /vulcanV2Player/i,
  /payments_sdk\/v1\/fbpay_sdk/i,
  /klaviyo\.com/i,
  // Routine misc. errors from klaviyo.js
  /post_identification_sync/i,
  // Analytics library used by Global-E that we cannot get rid of
  /forter\.com/i,
  // Attentive
  /cdn\.attn\.tv/i,
  // Taboola Shopify App
  /taboola\.com/i
];

/**
 * The cart api produces several scary looking errors that all amount to ephemeral networking
 * errors. There are other means of monitoring networking error volume. There is no point to
 * tracking these errors as issues to address.
 */
const routineShopifyCartErrors = [
  'Received status 503 fetching cart',
  'Received status 504 fetching cart',
  'Received status 403 updating cart',
  'Received status 429 updating cart',
  'Received status 502 updating cart',
  'Received status 503 updating cart',
  'Received status 504 updating cart',
  'Received status 400 changing cart',
  'Received status 502 changing cart',
  'Received status 503 changing cart',
  'Received status 502 adding to cart',
  'Received status 503 adding to cart'
];

const routineRecommendationErrors = [
  'Received status 503 fetching recommendations',
  'Received status 504 fetching recommendations'
];

const routineYotpoErrors = [
  'Received status 404 submitting vote'
];

const gladlyErrors = [
  // We have a hack that pre-fetches this to determine availability and avoid initializing so as to
  // avoid Gladly's infinite polling logic when unavailable, but that pre-fetch sometimes fails.
  // We do not care about most of the the reasons for those fetch failures.
  'Received status 403 fetching Gladly dynamic config'
];

const klaviyoErrors = [
  // Issues with running custom klaviyo next to Klaviyo's theme app embed block, these are usually
  // temporary, not important, but it is a console warning that causes spam
  'Already loaded for account',
  // Klaviyo error from post_identification_sync
  'Failed to send bulk events',
  // Klaviyo indexedDB error
  'One of the specified object stores was not found',
  // Klaviyo indexedDB error
  'in-progress transaction',
  // Klaviyo indexedDB error
  'invalid security context',
  // Klaviyo indexedDB error
  'database file on disk',
  // Klaviyo indexedDB error
  'disk is full',
  // Klaviyo indexedDB error
  'unable to open database file',
  // Klaviyo indexedDB error
  'Internal error opening database',
  // Klaviyo indexedDB error
  'IDBObjectStoreInfo',
  // Klaviyo indexedDB error
  'Indexed Database',
  // Klaviyo indexedDB error
  '_store.supported',
  // Klaviyo indexedDB error
  'table in database',
  // Klaviyo sdk error (with status 400 or 403 or etc)
  'identify failed with status',
  // Klaviyo indexedDB error
  'objectStoreNames',
  // Klaviyo indexedDB error
  'Database deleted',
  // Klaviyo indexedDB error
  'The connection was closed',
  // Klaviyo indexedDB error
  'Connection is closing',
  // Klaviyo indexedDB error
  'Version change transaction',
  // Klaviyo indexedDB error
  'Transaction timed out',
  // Klaviyo indexedDB error
  'opening backing store',
  // Klaviyo indexedDB error
  'access the database',
  // Klaviyo indexedDB error
  'Error creating Records',
  // Klaviyo indexedDB error
  'out of memory',
  // Klaviyo indexedDB error
  'IDB database file',
  // Klaviyo indexedDB error
  'Internal error committing transaction',
  // Klaviyo indexedDB error
  'Backend aborted error',
  // Klaviyo indexedDB error
  'start SQLite',
  // Klaviyo indexedDB error
  'mutation operation was attempted',
  // Klaviyo indexedDB error
  'exceeded its quota limitations'
];

/**
 * Errors we never care to know about. Some come from Shopify. Some come from the content scripts
 * injected by in-app browsers such as Instagram. Some come from third parties that have broken
 * code. Some must be ignored because we made the decision to use the CaptureConsole integration,
 * and some third parties log warnings on almost every page load, leading to excessive events.
 */
const ignoreErrors: SentryBrowser.BrowserOptions['ignoreErrors'] = [
  ...routineShopifyCartErrors,
  ...routineRecommendationErrors,
  ...routineYotpoErrors,
  ...klaviyoErrors,
  ...gladlyErrors,
  // Ephemeral networking error, this is not a programming error, this is just some visitor's
  // problem with their Internet connection where a fetch call fails
  'Load failed',
  // Ephemeral networking error, this is not a programming error, this is just some visitor's
  // problem with their Internet connection where a fetch call fails
  'Failed to fetch',
  // Common temporary networking errors that happen during fetch calls
  'NetworkError',
  // The error message is generated by Sentry when it encountered an unhandled rejection that is
  // not an instanceof Error. We cannot fix these issues. We avoid them by convention because in
  // our convention this kind of code is strictly forbidden. So these only happen because of bad
  // third party code such as Microsoft Outlook or a buggy extension.
  // Event `CustomEvent` (type=unhandledrejection) captured as promise rejection
  'captured as promise rejection',
  // Shopify web pixels strict loading error that is out of our control
  'Failed to get ServiceWorkerRegistration objects',
  // Routine user input error that is not a programming error but unfortunately is treated like
  // an error, this error is so frequent that it is better to filter it out on the frontend than
  // on the backend
  'Cart requirements not met for discount code',
  // Routine user input error when someone tries to apply an invalid discount code that occurs
  // so frequently it is better to ignore it on the front end than on the backend.
  'Unable to find a valid discount matching the code entered',
  // Routine user input error
  'Unable to apply this discount code',
  // Routine user input error when adding discount to checkout object, not programmer error
  'This discount is not valid anymore',
  // This commonly occurs because of pending fetches during page navigation
  'The operation was aborted',
  // This commonly occurs because of pending fetches during page navigation
  'AbortError',
  // Global-E fetch hook error. Global-E's javascript overwrites global fetch, and in doing so,
  // it incorrectly assumes that fetch works as normal, and it clones the request object, but not
  // all requests can be cloned, because we only partially polyfill fetch, so this completely
  // breaks all fetches in some older browsers. Global-E is not interested in fixing the error.
  // The only option is to stop using Global-E. For now this error is just spam and for now we
  // cannot support older browsers because of Global-E.
  'req.clone is not a function',
  // Shopify
  'Bugsnag',
  // Shopify
  'Shop is not configured to block privacy regulation',
  // Shopify has some init logic that some browsers do not support, or it has a bug where sometimes
  // the webPixelsManager object is not defined at the time of calling webPixelsManager.init()
  'webPixelsManager',
  // Instagram
  '__AutoFillPopupClose__',
  // Instagram bug in their injected code
  // https://connect.facebook.net/en_US/iab.autofill.enhanced.js
  '_AutofillCallbackHandler',
  // Shopify
  '[SPB]',
  // Gladly
  'Failed to fetch dynamic configuration',
  // Gladly
  'Failed to fetch configuration for appID',
  // Shopify
  'ppxo_',
  // Attentive loads its own gtag. Attentive's gtag logic has routine errors. We cannot do
  // anything about this error and Attentive will not fix it, so just ignore it.
  'b.container[a]',
  // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
  'Object Not Found Matching Id',
  // Ignore "Cannot read properties of undefined (reading 'domInteractive')". Not sure exactly what
  // keeps triggering this error, I think it is something interaction with the performance api in a
  // browser that does not support the performance api.
  'reading \'domInteractive\'',
  // A very frequent bug with Shopify's Shopify Pay React code
  'Consumer used without a Provider',
  // Weird warnings from Shopify Pay
  'js_v1.',
  '[getFeatureFlag.fail]',
  '[merge_features.fail]',
  // Shopify Monorail
  'Retry count of 5 exceeded',
  'Error producing to Monorail Edge',
  // unknown extension
  'PerformanceMonitoringJavascriptInterface',
  // Some kind of scraper/bot
  'Can\'t find variable: IFrameMessageHandler',
  // fairing performance metric
  'domContentLoadedEventEnd',
  // Unknown browser extension that routinely errors
  'ResizeObserver loop limit exceeded',
  // Shopify Boomerang
  '_boomrl',
  // Unknown, possibly Instagram IAB
  '_pcmBridgeCallbackHandler',
  // Unknown
  'startGetFields',
  // Unknown browser extension, possibly PayPal Honey
  'PopWidgetInterface',
  // We do not use this, so this error largely comes from extensions, so it is safe to ignore
  // See https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
  'ResizeObserver loop completed with undelivered notifications.',
  // cspell:ignore cobrowse
  // buggy browser extension from cobrowse
  'cobrowseIOgetRedactedElementsBounds',
  // A console warning from Shopify pixel that mysteriously started happening recently
  'getShopPrefs is deprecated',
  // Some buggy browser or chrome extension
  'findTopmostVisibleElement',
  // Some kind of scraper circumventing bot protection that has bugs
  // cspell:ignore converShodowDOM
  'converShodowDOM',
  // Some kind of scraper circumventing bot protection that has bugs
  'getDocHTMLAndText',
  // Tiktok console warning
  'This version has Break Change',
  // Something is constantly console.error logging this event, not our code
  'XMLHttpRequestProgressEvent'
];

/**
 * Initialize Sentry
 */
function main() {
  // These are the types subscribed in the loader snippet to capture events that occur prior to
  // Sentry init.
  const eventTypes = [
    'error',
    'unhandledrejection',
    'cart-update-erred'
  ];

  // Before init, remove the global listeners from the loader snippet. We will start listening using
  // local functions during and after init.
  for (const eventType of eventTypes) {
    removeEventListener(eventType, sentry_event_listener);
  }

  const initOptions = <SentryBrowser.BrowserOptions>{};

  initOptions.environment = 'production';
  try {
    initOptions.environment = determineEnvironment();
  } catch (error) {
    console.warn(error);
  }

  initOptions.release = createReleaseName();
  initOptions.dsn = sentry_dsn;
  initOptions.denyUrls = denyUrls;
  initOptions.ignoreErrors = ignoreErrors;
  initOptions.sendDefaultPii = true;

  initOptions.integrations = [
    SentryBrowser.breadcrumbsIntegration({
      console: initOptions.environment === 'production',
      dom: false,
      fetch: false,
      sentry: false,
      xhr: false
    }),
    SentryBrowser.browserApiErrorsIntegration({
      XMLHttpRequest: false
    }),
    SentryBrowser.captureConsoleIntegration({
      levels: ['error', 'warn']
    }),
    shopifyIntegration,
    SentryBrowser.extraErrorDataIntegration({ depth: 6 })
  ];

  initOptions.normalizeDepth = 10;
  initOptions.beforeBreadcrumb = beforeBreadcrumb;

  SentryBrowser.init(initOptions);

  const id = getShopifyClientId();
  if (id) {
    SentryBrowser.setUser({ id });
  }

  // Replay prior events (with some healthy paranoia).

  for (const event of sentry_event_queue) {
    try {
      replayEvent(event);
    } catch (error) {}
  }

  // Enable garbage collection of queued events and avoid any idempotency issues.
  sentry_event_queue = [];

  // Subscribe the local event listeners. We do not need to subscribe the global error handlers as
  // Sentry subscribes to the global events during init via a plugin.

  // TODO: unfortunately this functionality means that this module has too much knowledge of the
  // cart functionality. This is a violation of the principle of least knowledge. This module should
  // be refactored to couple to some globally supported event type, e.g. some kind of new
  // generic sentry-event that other code can choose to emit as a side effect. Or we can rely on
  // logging but there are issues there with timing.

  addEventListener('cart-update-erred', onCartUpdateErred);

  // Expose CLI
  window.sentry_test = test;
}

function getShopifyClientId() {
  let value;

  try {
    value = Cookie.read('_shopify_y');
  } catch (error) {
    // ignore
  }

  return value;
}

/**
 * This implicitly drops unknown events.
 *
 * This has no error safety.
 */
function replayEvent(event: Event) {
  if (isCartUpdateErredEvent(event) && event.detail) {
    onCartUpdateErred(event);
  } else if (isErrorEvent(event) && event.error instanceof Error) {
    SentryBrowser.captureException(event.error);
  } else if (isPromiseRejectionEvent(event) && event.reason instanceof Error) {
    SentryBrowser.captureException(event.reason);
  }
}

function isPromiseRejectionEvent(value: any): value is PromiseRejectionEvent {
  return value?.type === 'unhandledrejection';
}

function isErrorEvent(value: any): value is ErrorEvent {
  return value?.type === 'error';
}

function isCartUpdateErredEvent(value: any): value is WindowEventMap['cart-update-erred'] {
  return value?.type === 'cart-update-erred';
}

/**
 * The cart api contains several functions that emit error events instead of throwing errors. We
 * also decided that we did not want tracing coupled withe cart api. As a result, nothing traces the
 * errors, and nothing captures them. Therefore, we explicitly listen for those error events and
 * capture them here.
 *
 * This receives all errors from the cart service. This includes errors caused by users, such as bad
 * input, such as trying to use a discount code with a typo. We are not interested in capturing user
 * errors, because we only want to know about programmer errors. Aggregate analyses of codes with
 * typos is done in other systems. So we intentionally filter certain errors.
 */
function onCartUpdateErred(event: WindowEventMap['cart-update-erred']) {
  if (!event.detail) {
    return;
  }

  const code = event.detail.code;

  if (code === 'APPLY_DISCOUNTS') {
    console.log('not relaying event', event.type, event.detail);
    return;
  }

  if (code === 'ADD_ITEMS_OOS' || code === 'UPDATE_ITEMS_OOS') {
    console.log('not relaying event', event.type, event.detail);
    return;
  }

  SentryBrowser.captureException(event.detail.error, {
    extra: {
      cart: event.detail.cart,
      code,
      inputs: event.detail.inputs,
      scenario: event.detail.scenario,
      transaction_id: event.detail.transaction.id,
      transaction_name: event.detail.transaction.name
    }
  });

  console.log(event.type, event.detail);
}

function beforeBreadcrumb(breadcrumb: SentryTypes.Breadcrumb) {
  if (!breadcrumb) {
    return breadcrumb;
  }

  const message = breadcrumb.message;
  if (typeof message !== 'string') {
    return breadcrumb;
  }

  const ignores = [
    'Script error.'
  ];

  for (const ignore of ignores) {
    if (ignore === message) {
      return null;
    }
  }

  // Ignore messages from Meta's IAB code
  if (message.startsWith('FBNav')) {
    return null;
  }

  // Ignore messages produced by Shopify's own error diagnostic library
  if (message.includes('Bugsnag')) {
    return null;
  }

  // TikTok pixel spam
  if (message.startsWith('[Bridge]')) {
    return null;
  }

  // Ignore messages from Shopify Web Pixel warnings. This particular warning is because Shopify
  // deprecated some web pixel function, but there is an active web pixel on the site using the
  // function, and the pixel is maintained by Shopify. So they are using their own deprecated
  // functionality and we cannot do anything about it.
  if (message.includes('getShopPrefs')) {
    return null;
  }

  // Ignore Taboola Debug messages logged to console
  if (message.includes('[TABOOLA DEBUG]')) {
    return null;
  }

  return breadcrumb;
}

/**
 * Obtain a release name for tagging issues in Sentry
 *
 * This logic must be careful to consider to not error and to only provide a valid release name.
 *
 * * Shopify can change its API at any time, so Shopify.theme.id might be missing
 * * content_for_header may not specified before sentry script load or not present or invalid so
 *   Shopify global may be undefined
 * * third party vendor apps may incorrectly mess with globals
 * * there is no clear documentation on what characters are not allowed, theme names can
 *   contain misc. characters but some characters are rejected (e.g. "/"), release name cannot
 *   contain spaces because Sentry release report UI breaks
 */
function createReleaseName() {
  if (!window.Shopify) {
    return;
  }

  const id = Shopify.theme?.id;
  if (!Number.isInteger(id)) {
    return;
  }

  let name = Shopify.theme?.name;
  if (typeof name !== 'string') {
    return `${id}`;
  }

  // Sentry rejects events that have a release containing a forward slash.
  name = name.replace(/\//g, '-');

  name = name.trim();

  // Sentry's accepts release names that contain whitespace but the reporting UI breaks when it
  // tries to client side parse a release name containing a space on render.
  name = name.replace(/\s+/g, '-');

  return name ? `${id}-${name}` : `${id}`;
}

/**
 * Returns the stage of the current deployment. This involves some tricky logic to account for the
 * many ways in which the site can be deployed and issues with some developers using incorrect test
 * deployment settings.
 *
 * Be careful with changing the return values. Some users of this function such as Sentry naively
 * forward along the output value to a backend system that processes data based upon this exact
 * string.
 *
 * This cannot rely on NODE_ENV as NODE_ENV has other meanings during CI/CD deployment.
 */
function determineEnvironment() {
  if (!window.Shopify) {
    return 'development';
  }

  if (Shopify.inspectMode) {
    return 'development';
  }

  // Detect when the website was loaded as a Shopify theme preview (or sometimes in editor mode).
  if (Shopify.theme?.role === 'unpublished') {
    return 'development';
  }

  return 'production';
}

/**
 * Adds some Shopify properties to events.
 */
const shopifyIntegration: SentryTypes.Integration = {
  name: 'ShopifyIntegration',
  processEvent(event, _hint, client) {
    if (SentryBrowser.getClient() !== client) {
      return event;
    }

    if (!window.Shopify) {
      return;
    }

    // This does not use spread to clone because not all contexts are cloneable.

    event.contexts = event.contexts || {};

    event.contexts.shopify_theme = {
      country: Shopify.country,
      currency: Shopify.currency?.active,
      locale: Shopify.locale,
      market_handle: sentry_market_handle,
      theme_handle: Shopify.theme?.handle,
      theme_id: Shopify.theme?.id,
      theme_name: Shopify.theme?.name,
      theme_role: Shopify.theme?.role
    };

    return event;
  }
};

async function test(message?: string) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < 10; i++) {
    randomString += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
  }

  const errorMessage = message || ('sentry test capture exception ' + randomString);
  const eventId = SentryBrowser.captureException(new Error(errorMessage));
  await SentryBrowser.flush();
  return eventId;
}

main();
